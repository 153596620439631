@tailwind base;
@tailwind components;
@tailwind utilities;


.text-wrap {
  position: relative;
  max-height: 4.5em; /* 3 lines of text */
  overflow: hidden;
}

.text-wrap::after {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 0;
  background: white;
  padding-left: 1em;
}


/* Add this to your CSS file or within a <style> tag in your component */
.skeleton {
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0, #f0f0f0, #e0e0e0);
  background-size: 200% 100%;
  background-position: 200% center;
  animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% center;
  }
  100% {
    background-position: -200% center;
  }
}